import React, { useEffect } from "react";
import LayoutFull from "../layouts/layoutFull/LayoutFull";
import styles from './404.module.scss';
import tawkToChat from "../components/TawkToChat";
// import Head from "next/head";
import getPages from "../helpers/data/get/pages";
import SeoApiHandler from "../helpers/seo/SeoApiHandler";

export async function getStaticProps() {
    return {
        props: {seo: await getPages('/404')},
    }
}

function NotFound({seo}) {

    useEffect(() => {
        tawkToChat();
    }, []);

    return (
        <LayoutFull>
            <SeoApiHandler data={seo}/>
            <div className='page-content'>
                <div className={`page-content__block ${styles['not-found-page']}`}>
                    <div className={`page-content__block__container-centered ${styles['not-found-page__container']}`}>
                        <div className={styles['not-found-page__container__error']}>404</div>
                        <div className={styles['not-found-page__container__content']}>
                            <h1 className={styles['not-found-page__container__content__title']}>Nothing found here</h1>
                            <p className={styles['not-found-page__container__content__message']}>
                                There is no content on this page. If you expected some or faced an issue
                                please <a onClick={() => Tawk_API.toggle({start: ''})}>contact support</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutFull>
    );
}

export default NotFound