import { ENV_LOCAL } from "../config";

export default function tawkToChat() {

    // if (ENV_LOCAL) return;

    const script = document.createElement("script");
    script.src = "//embed.tawk.to/64be1dd3cc26a871b02ace1f/1h63a90n2";
    script.async = true;
    script.charSet = "UTF-8";
    script.setAttribute('crossorigin','*');
    document.body.appendChild(script);
}